// TranscriptCard.tsx
import { generateTranscript } from "../../../utils/apiCall";
import DocumentCard from "../../Common/DocumentCard";
import { IDocument } from "../../../types/userCourse";

const TranscriptCard = ({
  transcript,
  activeTranscript,
}: {
  transcript: IDocument;
  activeTranscript: "available" | "claimed" | "pending";
}) => {
  return (
    <DocumentCard
      document={transcript}
      activeDocument={activeTranscript}
      onGenerate={generateTranscript}
      documentType="Transcript"
    />
  );
};

export default TranscriptCard;

import { Box } from "@mui/material";

import { useContext } from "react";
import AppContext from "../../contexts/AppContext";
import { getUserCourses } from "../../utils/apiCall";
import MyCertificates from "./MyCertificates/MyCertificates";
import { useQuery } from "@tanstack/react-query";
import Dashboard from "./Dashaboard/_Dashboard";
import MyCourses from "./MyCourses/MyCourses";
import { IUserCourseInfo } from "../../types/userCourse";
import BundleCourses from "./BundleCourses/BundleCourses";
import UnlimitedLearning from "./UnlimitedLearning/UnlimitedLearning";
import StudentCard from "./StudentIdCard/StudentCard";
import MyTranscripts from "./MyTranscripts/MyTranscripts";

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

const TabContent = ({ value }: { value: string }) => {
  const userToken = useContext(AppContext).token;

  const { data: courseInfo, isLoading } = useQuery<IUserCourseInfo>({
    queryKey: ["userCourseInfo"],
    queryFn: () => getUserCourses(userToken),
    enabled: !!userToken,
  });
  console.log(courseInfo);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <TabPanel value={value} index={"dashboard"}>
        {!isLoading && courseInfo && <Dashboard courseInfo={courseInfo} />}
      </TabPanel>
      <TabPanel value={value} index={"my-courses"}>
        <MyCourses
          userCorseInfoList={{
            enrolled_courses: courseInfo?.enrolled_courses ?? [],
            complete_courses: courseInfo?.complete_courses ?? [],
            inprogress_courses: courseInfo?.inprogress_courses ?? [],
            course_certificates: courseInfo?.course_certificates ?? [],
          }}
        />
      </TabPanel>
      <TabPanel value={value} index={"my-certificate"}>
        {<MyCertificates />}
      </TabPanel>
      <TabPanel value={value} index={"bundle-courses"}>
        <BundleCourses />
      </TabPanel>
      <TabPanel value={value} index={"unlimited-learning"}>
        <UnlimitedLearning />
      </TabPanel>
      <TabPanel value={value} index={"student-card"}>
        <StudentCard />
      </TabPanel>
      <TabPanel value={value} index={"my-transcript"}>
        <MyTranscripts />
      </TabPanel>
    </>
  );
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      sx={{
        mt: {
          xs: 6,
          md: 12,
        },
        height: "100%",
        width: "100%",
        px: { xs: 2, sm: 3, md: 4 },
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}
export default TabContent;

import * as React from "react";
import MainSidebar from "./MainSidebar";
import MobileSidebar from "./MobileSidebar";

interface SidebarProps {
  open: boolean;
  handleDrawerClose: () => void;
  tabValue: string;
  handleChange: (newValue: string) => void;
  mobileSidebarOpen: boolean;
  onHandleMobileSidebarClose: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  open,
  handleDrawerClose,
  tabValue,
  handleChange,
  mobileSidebarOpen,
  onHandleMobileSidebarClose,
}) => {
  return (
    <>
      <MainSidebar
        open={open}
        handleDrawerClose={handleDrawerClose}
        tabValue={tabValue}
        handleChange={handleChange}
      />
      <MobileSidebar
        tabValue={tabValue}
        open={mobileSidebarOpen}
        handleTabChange={handleChange}
        onHandleDrawerClose={onHandleMobileSidebarClose}
      />
    </>
  );
};

export default Sidebar;

import { Box, Card, Grid, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import { useContext } from "react";
import AppContext from "../../../contexts/AppContext";
const UserRewards = ({
  total_rewards,
  login_day_count,
}: {
  total_rewards: number;
  login_day_count: string;
}) => {
  const rootState = useContext(AppContext);
  const userActiveSubscription = rootState.subscription_info
    ?.active_subscription || {
    status: "inactive",
  };
  console.log(userActiveSubscription);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Card
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            height: "15rem",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "1rem",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Typography variant="h3" component="span" fontWeight={"bold"}>
              {total_rewards}
            </Typography>
            <EmojiEventsIcon
              sx={{
                fontSize: "3rem",
                color: "gold",
              }}
            />
          </Box>
          <Typography variant="h5" component="h6">
            Rewards Points
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: "100%",
            height: "15rem",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Typography variant="h3" component="span" fontWeight={"bold"}>
              {login_day_count}
            </Typography>
            <CloudUploadIcon
              sx={{
                fontSize: "3rem",
              }}
            />
          </Box>
          <Typography variant="h5" component="h6">
            Days Logged In
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        {/* subscription status */}

        <Card
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: "100%",
            height: "15rem",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Typography
              variant="h5"
              component="span"
              fontWeight={"bold"}
              sx={{
                color:
                  userActiveSubscription?.status === "active" ? "green" : "red",
                textTransform: "capitalize",
              }}
            >
              {userActiveSubscription?.status}
            </Typography>
            {userActiveSubscription?.status === "active" ? (
              <AddAlertIcon
                sx={{
                  fontSize: "3rem",
                  color: "primary.main",
                }}
              />
            ) : (
              <CreditCardOffIcon
                sx={{
                  fontSize: "3rem",
                  color: "red",
                }}
              />
            )}
          </Box>
          <Typography variant="h5" component="h6">
            Subscription Status
          </Typography>
        </Card>
      </Grid>
    </Grid>
  );
};

export default UserRewards;

import { Tab, Tabs, useTheme } from "@mui/material";

const DashboardNumbers = ({
  numbers,
  activeValue,
  handleActive,
}: {
  numbers: {
    value: any;
    label: string;
    count: number;
  }[];
  activeValue: any;
  handleActive: (event: React.SyntheticEvent, newValue: any) => void;
}) => {
  const theme = useTheme();
  return (
    <Tabs
      value={activeValue}
      onChange={handleActive}
      textColor="primary"
      indicatorColor="primary"
      aria-label="secondary tabs example"
      sx={{ marginBottom: "1rem" }}
    >
      {numbers.map((item) => {
        return (
          <Tab
            key={item.value}
            label={`${item.label} (${item.count})`}
            value={item.value}
            sx={{
              textTransform: "capitalize",

              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "15rem",
              height: "3.375rem",
              fontSize: "1.125rem",
              bgcolor: theme.palette.mode === "dark" ? "#2E323E" : "#F6F6FA",

              "&.Mui-selected": {
                bgcolor: "#fff",
                color: "primary.400",
                fontWeight: 600,
              },
            }}
          />
        );
      })}
    </Tabs>
  );
};

export default DashboardNumbers;

import { Box, Grid, Typography } from "@mui/material";
import { IUserCourse } from "../../types/userCourse";
import { userCurseInfoTitle } from "../../utils/maipping";
import primaryBg from "../../assets/images/primary-course-count-bg.png";
import secondaryBg from "../../assets/images/secondary-course-count-bg.png";
const UserCourseInfo = ({
  courseInfo,
}: {
  courseInfo: {
    enrolled_courses: IUserCourse[];
    complete_courses: IUserCourse[];
    inprogress_courses: IUserCourse[];
    course_certificates: string[];
  };
}) => {
  return (
    <Grid container spacing={2}>
      {Object.entries(courseInfo).map(([key, value], index) => {
        return (
          <Grid key={key} item xs={12} sm={6} md={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "2rem",
                padding: "1.5rem",
                height: "10rem",
                backgroundImage: `url(${
                  index % 2 === 0 ? primaryBg : secondaryBg
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "white",
                  fontSize: "1.5rem",
                  fontWeight: 500,
                }}
              >
                {userCurseInfoTitle[key]}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: "white",
                  fontWeight: 700,
                  fontSize: "3rem",
                }}
              >
                {value.length}
              </Typography>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default UserCourseInfo;

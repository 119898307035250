import { Box, Divider, Typography } from "@mui/material";

const ContentTitle = ({ title }: { title: string }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} my={4}>
      <Typography
        variant="h4"
        gutterBottom
        component={"h2"}
        sx={{
          color: (theme) => theme.palette.text.primary,
          fontSize: "1.5rem",
          fontWeight: 600,
        }}
      >
        {title}
      </Typography>
      <Divider sx={{ width: "100%", margin: "1rem 0" }} />
    </Box>
  );
};

export default ContentTitle;

import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { useState } from "react";
import CommonModal from "../Common/CommonModal";
import RightArrow from "../Common/icons/RightArrow";
import CommonButton from "../Common/buttons/CommonButton";
import SiteSnackBar from "../Common/SiteSnackBar";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IDocument } from "../../types/userCourse";

interface DocumentCardProps {
  document: IDocument;
  activeDocument: "available" | "claimed" | "pending";
  onGenerate: (courseId: number) => Promise<void>;
  documentType: "Certificate" | "Transcript";
}

const DocumentCard = ({
  document,
  activeDocument,
  onGenerate,
  documentType,
}: DocumentCardProps) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error" | "info" | "warning";
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCourseView = () => {
    window.open("/certificate?" + document.slug, "_blank");
  };

  const mutation = useMutation({
    mutationFn: () => onGenerate(parseInt(document.course_id)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["certificateList"] });
      setSnackbarInfo({
        open: true,
        message: `${documentType} generated successfully`,
        severity: "success",
      });
    },
    onError: () => {
      setSnackbarInfo({
        open: true,
        message: `Failed to generate ${documentType.toLowerCase()}`,
        severity: "error",
      });
    },
  });

  return (
    <>
      <Card
        sx={{
          maxWidth: 345,
          bgcolor: "white",
          height: "100%",
          borderRadius: "1rem",
          boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.10)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <CardActionArea
          sx={{
            flex: "1",
          }}
        >
          <CardMedia
            component="img"
            sx={{
              height: "12.5rem",
              objectFit: "cover",
              width: 345,
            }}
            src={document.featured_image.toString() ?? ""}
            alt={document.title}
          />
          <CardContent
            sx={{
              gap: "1rem",
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography gutterBottom variant="h6" color={"#2E323E"}>
              {document.title}
            </Typography>

            {activeDocument === "claimed" ? (
              <>
                <CommonModal
                  open={open}
                  handleClose={() => setOpen(false)}
                  pdfUrl={
                    documentType === "Certificate"
                      ? document.certificate_url
                      : document.transcript_url
                  }
                  title={documentType}
                />
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{
                    borderRadius: "1.5rem",
                    bgcolor: "primary.400",
                    color: "white",
                    "&:hover": {
                      bgcolor: "primary.600",
                    },
                    height: "3rem",
                  }}
                  onClick={() => setOpen(true)}
                >
                  View {documentType}
                </Button>
              </>
            ) : activeDocument === "available" ? (
              <CommonButton
                buttonText={
                  mutation.isPending
                    ? "Generating..."
                    : `Generate ${documentType}`
                }
                onHandleClick={() => mutation.mutate()}
                disabled={mutation.isPending}
              />
            ) : null}
          </CardContent>
        </CardActionArea>
        <CardActions
          sx={{
            mx: "0.5rem 0",
            p: "0",
          }}
        >
          <Button
            size="large"
            color="primary"
            variant="contained"
            sx={{
              borderRadius: "0",
              height: "3rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              width: "100%",
              bgcolor: "primary.400",

              "&:hover": {
                bgcolor: "secondary.600",
                color: "black",
              },
            }}
            endIcon={<RightArrow stroke={"#F6F6FA"} />}
            onClick={handleCourseView}
          >
            {`Order Hard Copy ${documentType}`}
          </Button>
        </CardActions>
      </Card>
      <SiteSnackBar
        key={document.course_id}
        open={snackbarInfo.open}
        message={snackbarInfo.message}
        severity={snackbarInfo.severity}
        handleClose={() => setSnackbarInfo({ ...snackbarInfo, open: false })}
      />
    </>
  );
};

export default DocumentCard;

import { ISubscription } from "./subscription";

interface AppState {
  token: string;
  subscription_info: ISubscription | null;
  loading: boolean;
}

type AppAction =
  | { type: AppActionType.SET_TOKEN; payload: string }
  | { type: AppActionType.SET_SUBSCRIPTION_INFO; payload: string | null };

export enum AppActionType {
  SET_TOKEN = "SET_TOKEN",
  SET_SUBSCRIPTION_INFO = "SET_SUBSCRIPTION_INFO",
  SET_LOADING = "SET_LOADING",
}

interface IAccordion {
  title: string;
  content: string;
  expanded: boolean;
  id: string;
}

export interface ILearningProduct {
  id: number;
  name: string;
  title: string;
  price: string;
  regular_price: string;
  discount_percent: number;
  short_description: string;
}

export type { AppState, AppAction, IAccordion };
export interface AllTabSlugs {
  label: string;
  slug: string;
  icon?: JSX.Element;
}

export interface IUserInfo {
  id: string;
  username: string;
  slug: string;
  email: string;
  exp: number;
  member_type: boolean;
  refresh_token: string;
  avatar: string;
  displayname: string;
  roles: string[];
  caps: Caps;
  profile_link: string;
}

export interface Caps {
  "0": boolean;
  manage_options: boolean;
  edit_posts: boolean;
  read: boolean;
  edit_appointments: number;
}

export type userCourseTypes =
  | "enrolled_courses"
  | "inprogress_courses"
  | "complete_courses"
  | "pending_certification";

import { Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { AllTabSlugs } from "../../types";
import { allTabList } from "../../utils/data";
import UnlimitedAccess from "./UnlimitedAccess";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
const MobileSidebar = ({
  open,
  onHandleDrawerClose,
  tabValue,
  handleTabChange,
}: {
  open: boolean;
  onHandleDrawerClose: () => void;
  tabValue: string;
  handleTabChange: (newValue: string) => void;
}) => {
  const DrawerList = (
    <Box
      sx={{ width: 280 }}
      my={"2rem"}
      role="presentation"
      onClick={onHandleDrawerClose}
    >
      <Tabs
        orientation="vertical"
        onChange={(_, newValue) => handleTabChange(newValue)}
        value={tabValue}
        sx={{
          "& .MuiTabs-flexContainer": {
            alignItems: "start",
            justifyContent: "flex-start",
          },
        }}
      >
        {allTabList.map((tab: AllTabSlugs) => (
          <Tab
            key={tab.slug}
            label={tab.label}
            value={tab.slug}
            iconPosition="end"
            sx={{
              justifyContent: "space-between",
              width: "100%",
              minHeight: 50,
            }}
            icon={<ChevronRightIcon />}
          />
        ))}
      </Tabs>
    </Box>
  );

  return (
    <Drawer
      open={open}
      sx={{
        zIndex: 1300,
        px: "1.5rem",
        "& .MuiDrawer-paper": {
          justifyContent: "space-between",
        },
        display: {
          xs: "flex",
          md: "none",
        },
      }}
      onClose={onHandleDrawerClose}
    >
      {DrawerList}
      <UnlimitedAccess sideBarOpen={true} />
    </Drawer>
  );
};

export default MobileSidebar;

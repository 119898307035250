import { Button, SxProps, Theme } from "@mui/material";
import RightArrow from "../icons/RightArrow";

const CommonButton = ({
  onHandleClick,
  buttonText,
  endIconStrokeColor,
  buttonStyles,
  hasButtonIcon = false,
  linkUrl,
  ...props
}: {
  onHandleClick?: () => void;
  buttonText: string;
  endIconStrokeColor?: string;
  buttonStyles?: SxProps<Theme> | undefined;
  hasButtonIcon?: boolean;
  linkUrl?: string;
  [x: string]: any;
}) => {
  return (
    <Button
      size="large"
      color="primary"
      variant="contained"
      sx={{
        borderRadius: "1.5rem",
        height: "3rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textTransform: "capitalize",
        fontSize: "0.875rem",
        bgcolor: "primary.400",
        width: "100%",
        fontWeight: "700",
        "&:hover": {
          bgcolor: "secondary.600",
          color: "black",
        },
        ...buttonStyles,
      }}
      endIcon={
        hasButtonIcon ? <RightArrow stroke={endIconStrokeColor} /> : undefined
      }
      {...(linkUrl && {
        href: linkUrl,
        target: "_blank",
      })}
      {...(onHandleClick && { onClick: onHandleClick })}
      {...props}
    >
      {buttonText}
    </Button>
  );
};

export default CommonButton;

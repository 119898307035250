import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import TabContent from "../Contents/TabContent";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import { useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CartSidebar from "../Sidebar/CartSidebar/CartSidebar";
import { useQuery } from "@tanstack/react-query";
import { getWooCartItems } from "../../utils/apiCall";

const RootComponent = () => {
  const [open, setOpen] = useState(true);
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const [value, setValue] = useState("dashboard");
  const smallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  useEffect(() => {
    if (smallScreen) {
      setOpen(false);
    }
  }, [smallScreen]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };
  const [cartOpen, setCartOpen] = useState(false);

  const { data: cartInfo } = useQuery({
    queryKey: ["cartInfo"],
    queryFn: getWooCartItems,
  });
  return (
    <Box sx={{ display: "flex", bgcolor: "#f8f8f8", height: "100%" }}>
      <CssBaseline />
      <Header
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        mobileSidebarOpen={mobileSidebarOpen}
        onHandleMobileSidebarOpen={() => setMobileSidebarOpen(true)}
        onHandleCartOpen={() => setCartOpen(true)}
        cartItemsNumber={cartInfo?.items_count ?? 0}
      />

      <Sidebar
        open={open}
        handleDrawerClose={handleDrawerClose}
        tabValue={value}
        handleChange={handleChange}
        mobileSidebarOpen={mobileSidebarOpen}
        onHandleMobileSidebarClose={() => setMobileSidebarOpen(false)}
      />
      <CartSidebar
        open={cartOpen}
        onHandleDrawerClose={() => setCartOpen(false)}
        cartItems={cartInfo?.items ?? []}
        total={cartInfo?.totals?.total_price ?? 0}
      />
      <TabContent value={value} />
    </Box>
  );
};

export default RootComponent;

import { Box, Typography } from "@mui/material";
import lock from "../../assets/images/unlimited-access-lock.svg";
import CommonButton from "../Common/buttons/CommonButton";
const UnlimitedAccess = ({ sideBarOpen }: { sideBarOpen: boolean }) => {
  console.log({
    sideBarOpen,
  });
  return (
    <Box
      sx={{
        display: sideBarOpen ? "flex" : "none",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "1rem",
        backgroundColor: "#f5f5f5",
        width: "15rem",
        borderRadius: "2rem",
        padding: "1rem",
        mx: "auto",
        mb: "2rem",
      }}
    >
      <Typography
        component={"img"}
        sx={{
          width: "5rem",
          height: "5rem",
        }}
        src={lock}
        alt="lock"
      />
      <Typography
        variant="body1"
        sx={{
          textAlign: "center",
          textWrap: "wrap",
          color: "grey.500",
        }}
      >
        Get{" "}
        <span
          style={{
            color: "#0F217D",
            fontWeight: 700,
            fontSize: "1.2rem",
          }}
        >
          Unlimited Access
        </span>{" "}
        to{" "}
        <span
          style={{
            color: "#0F217D",
            fontWeight: 700,
            fontSize: "1.2rem",
          }}
        >
          100+ Courses{" "}
        </span>
        for a year!
      </Typography>
      <CommonButton buttonText="Get Access" linkUrl="/cart/?add-to-cart=789" />
    </Box>
  );
};

export default UnlimitedAccess;

import { Grid } from "@mui/material";
import { IDocument } from "../../../types/userCourse";

import TranscriptCard from "./TranscriptCard";

const TranscriptList = ({
  displayTranscripts,
  activeNumber,
}: {
  displayTranscripts: IDocument[];
  activeNumber: "available" | "claimed" | "pending";
}) => {
  return (
    <Grid container spacing={2}>
      {displayTranscripts?.map((transcript) => (
        <Grid item key={transcript.course_id}>
          <TranscriptCard
            transcript={transcript}
            activeTranscript={activeNumber}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default TranscriptList;

import { createTheme } from "@mui/material";
import { useState, useMemo } from "react";

const useCustomTheme = () => {
  const [mode, setMode] = useState<"light" | "dark">("light");
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: "#0f217d",
            dark: "#0f217d",
            contrastText: "#fff",
            50: "#e8eaf6",
            100: "#b5bad7",
            200: "#9199c3",
            300: "#5e6aa8",
            400: "#3f4d97",
            500: "#0f217d",
            600: "#0e1e72",
            700: "#0b1759",
            800: "#081245",
            900: "#060e35",
          },
          secondary: {
            main: "#16c2d5",
            dark: "#16c2d5",
            contrastText: "#fff",
            50: "#e8f9fb",
            100: "#b7ecf2",
            200: "#94e3ec",
            300: "#63d6e3",
            400: "#45cedd",
            500: "#16c2d5",
            600: "#14b1c2",
            700: "#108a97",
            800: "#0c6b75",
            900: "#095159",
          },
          grey: {
            50: "#eaecee",
            100: "#bec5c9",
            200: "#9fa9af",
            300: "#73828a",
            400: "#586973",
            500: "#2e4450",
            600: "#2a3e49",
            700: "#213039",
            800: "#19252c",
            900: "#131d22",
          },
          text: {
            primary: "#2E323E",
            secondary: "#586973",
          },
        },
        typography: {
          fontFamily: ["Lato ", "sans-serif"].join(","),
        },
      }),
    [mode]
  );

  return { theme, colorMode };
};

export default useCustomTheme;

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Box, LinearProgress, Typography } from "@mui/material";
import { userCourseTypes } from "../../../types";
import { IUserCourse } from "../../../types/userCourse";
import BookIcon from "../icons/BookIcon";

const ContentBaseOnStatus = ({
  courseInfo,
  courseBtnStatus,
}: {
  courseInfo: IUserCourse;
  courseBtnStatus: userCourseTypes;
}) => {
  const courseDurationInHours = Math.ceil(
    courseInfo.total_duration_in_seconds / 3600
  ).toFixed(2);
  if (
    courseBtnStatus === "enrolled_courses" ||
    courseBtnStatus === "pending_certification"
  ) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            height: "2rem",
            "& hr": {
              color: "red",
              width: "2px",
            },
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
            <BookIcon />
            <Typography
              variant="body2"
              sx={{
                fontSize: {
                  xs: "11px",
                  md: "0.875rem",
                },
                color: (theme) =>
                  theme.palette.mode === "light" ? "text.primary" : "white",
              }}
            >
              {` ${courseInfo.curriculums_count} Modules`}
            </Typography>
          </Box>

          <hr
            style={{
              color: "#BEC5C9",
              width: "2px",
              height: "1.5rem",
            }}
          />
          <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
            <AccessTimeIcon />
            <Typography
              variant="body2"
              sx={{
                fontSize: {
                  xs: "11px",
                  md: "0.875rem",
                },
                color: (theme) =>
                  theme.palette.mode === "light" ? "text.primary" : "white",
              }}
            >
              {`${courseDurationInHours} Hours`}
            </Typography>
          </Box>
        </Box>
      </>
    );
  } else {
    return (
      <Box sx={{ display: "flex", gap: "0.5rem", flexDirection: "column" }}>
        <Typography
          variant="body2"
          color="text.primary"
          sx={{ fontSize: "0.875rem" }}
        >
          {courseInfo.progress || 0}% Completed
        </Typography>
        <LinearProgress
          variant="determinate"
          value={Number(courseInfo.progress || 0)}
          sx={{
            height: "0.5rem",
            borderRadius: "0.5rem",
            mr: "0.5rem",
          }}
        />
      </Box>
    );
  }
};

export default ContentBaseOnStatus;

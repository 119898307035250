import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, useTheme } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { CSSObject, styled, Theme } from "@mui/material/styles";
import logo from "../../assets/images/dashboard-logo.png";
import AllTabs from "./AllTabs/AllTabs";
import UnlimitedAccess from "./UnlimitedAccess";
const drawerWidth = 280;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface MainSidebarProps {
  open: boolean;
  handleDrawerClose: () => void;
  tabValue: string;
  handleChange: (newValue: string) => void;
}
const MainSidebar = ({
  open,
  handleDrawerClose,
  tabValue,
  handleChange,
}: MainSidebarProps) => {
  const theme = useTheme();
  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        "& .MuiDrawer-paper": {
          bgcolor: "primary.main",
          border: "none",
        },

        display: {
          xs: "none",
          md: "block",
        },
      }}
    >
      <DrawerHeader
        sx={{
          bgcolor: "primary.main",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            margin: "1rem 0",
          }}
        >
          <img src={logo} alt="logo" />
        </Box>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon
              sx={{
                color: "secondary.contrastText",
              }}
            />
          ) : (
            <ChevronLeftIcon
              sx={{
                color: "secondary.contrastText",
              }}
            />
          )}
        </IconButton>
      </DrawerHeader>
      <AllTabs
        value={tabValue}
        sideBarOpen={open}
        handleChange={handleChange}
      />
      <UnlimitedAccess sideBarOpen={open} />
    </Drawer>
  );
};

export default MainSidebar;

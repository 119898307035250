import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import noImage from "../../../assets/images/no-image-react.jpg";
import CartNavigation from "./CartNavigation";
import parse from "html-react-parser";
const CartSidebar = ({
  open,
  onHandleDrawerClose,
  cartItems,
  total,
}: {
  open: boolean;
  onHandleDrawerClose: () => void;
  cartItems: any[];
  total: number;
}) => {
  const DrawerList = (
    <Box sx={{ width: 300 }} my={"2rem"} role="presentation">
      <List>
        {cartItems?.map((item) => (
          <ListItem
            key={item?.key}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Typography
              component={"img"}
              src={item?.images[0]?.src ?? noImage}
              alt={item?.name}
              sx={{ width: 50, height: 50, objectFit: "contain" }}
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <ListItemText
                primary={parse(item?.name)}
                secondary={`${item?.quantity}x ${
                  item?.prices?.currency_symbol
                }${parseInt(item?.prices?.price) / 100}`}
                sx={{
                  "& .MuiListItemText-primary": {
                    fontSize: "1rem",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? theme.palette.grey[800]
                        : theme.palette.grey[300],
                  },
                  "& .MuiListItemText-secondary": {
                    fontSize: "0.75rem",
                    color: "#666666",
                  },
                }}
              />
              {/* quantity increment decrement */}

              {/* <CartItemIncrement courseQuantity={item?.quantity} /> */}
            </Box>
          </ListItem>
        ))}
      </List>
      <Divider />
      <CartNavigation
        currencySymbol={cartItems?.[0]?.prices?.currency_symbol}
        total={total / 100}
      />
    </Box>
  );

  return (
    <Drawer
      open={open}
      sx={{
        zIndex: 1300,
        px: "1.5rem",
        "& .MuiDrawer-paper": {
          justifyContent: "space-between",
        },
      }}
      anchor="right"
      onClose={onHandleDrawerClose}
    >
      {DrawerList}
    </Drawer>
  );
};

export default CartSidebar;

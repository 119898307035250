import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelIcon from "@mui/icons-material/Cancel";
import { compareList } from "../../../utils/data";

const ProductCompare = () => {
  return (
    <Paper
      sx={{
        padding: "2.5rem",
        margin: "1rem",
        borderRadius: "1.5rem",
        width: "50%",
        height: "100%",
        display: {
          xs: "none",
          md: "block",
        },
        boxShadow: "0px 2px 12px 0px rgba(20, 20, 43, 0.08)",
      }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              "& th, & td": {
                borderBottom: "2px solid #EAECEE",
              },
            }}
          >
            <TableCell
              sx={{
                opacity: "0",
              }}
            >
              Features
            </TableCell>
            <TableCell
              align="center"
              sx={{
                fontSize: "1.125rem",
                fontWeight: "bold",
                color: "grey.400",
                textTransform: "uppercase",
              }}
            >
              Prime
            </TableCell>
            <TableCell
              align="center"
              sx={{
                fontSize: "1.125rem",
                fontWeight: "bold",
                color: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[800]
                    : theme.palette.grey[300],
                textTransform: "uppercase",
              }}
            >
              Lifetime Prime
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {compareList.map((row) => (
            <TableRow key={row.title} sx={{ "& td, & th": { border: 0 } }}>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  fontSize: "1.125rem",
                  fontWeight: "500",
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? theme.palette.grey[800]
                      : theme.palette.grey[300],
                }}
              >
                {row.title}
              </TableCell>
              <TableCell align="center">
                {row.prime ? (
                  <CheckCircleRoundedIcon color="secondary" />
                ) : (
                  <CancelIcon color="error" />
                )}
              </TableCell>
              <TableCell align="center">
                {row.lifetime ? (
                  <CheckCircleRoundedIcon color="secondary" />
                ) : (
                  <CancelIcon color="error" />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default ProductCompare;

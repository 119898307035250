import { AllTabSlugs, IAccordion } from "../types";

export const allTabList: AllTabSlugs[] = [
  {
    label: "Dashboard",
    slug: "dashboard",
  },
  {
    label: "My Courses",
    slug: "my-courses",
  },
  {
    label: "My Certificate",
    slug: "my-certificate",
  },
  {
    label: "My Transcript",
    slug: "my-transcript",
  },
  {
    label: "Student Card",
    slug: "student-card",
  },
  // {
  //   label: "My Rewards",
  //   slug: "my-rewards",
  // },
  {
    label: "Unlimited Learning",
    slug: "unlimited-learning",
  },
  {
    label: "Bundle Courses",
    slug: "bundle-courses",
  },
  // {
  //   label: "Special Offers",
  //   slug: "special-offers",
  // },
];

export const compareList = [
  {
    title: "Access to all courses ",
    prime: true,
    lifetime: true,
  },
  {
    title: "Unlimited Free CPD Certificate (PDF)",
    prime: true,
    lifetime: true,
  },
  {
    title: "Career Bundles",
    prime: false,
    lifetime: true,
  },
  {
    title: "Hardcopy and Transcript",
    prime: false,
    lifetime: true,
  },
  {
    title: "Student ID Card",
    prime: false,
    lifetime: true,
  },
];

export const accordionList: IAccordion[] = [
  {
    title: "What is a bundle course?",
    content:
      "A bundle course is a collection of multiple courses that are bundled together and sold as a single course. It is a great way to save money and time by purchasing multiple courses at once.",
    expanded: true,
    id: "1",
  },
  {
    title: "How do I enroll in a bundle course?",
    content:
      "To enroll in a bundle course, simply click on the 'Enroll Now' button on the course page. You will be redirected to the checkout page where you can complete the payment and enroll in the course.",
    expanded: false,
    id: "2",
  },
  {
    title: "Can I purchase individual courses from a bundle?",
    content:
      "Yes, you can purchase individual courses from a bundle. Simply click on the course title to view the course details and enroll in the course.",
    expanded: false,
    id: "3",
  },
  {
    title: "How long do I have access to a bundle course?",
    content:
      "You will have lifetime access to a bundle course once you enroll in it. You can access the course content at any time and complete it at your own pace.",
    expanded: false,
    id: "4",
  },
];

export const idCardBenefits = [
  {
    title: "1-Year Validity",
    content:
      "Your student ID card is valid for an entire year, ensuring continuous benefits throughout your studies.",
  },
  {
    title: "Proof of Enrolment",
    content:
      "Use your ID as official proof of enrolment at our organisation, recognised across various institutions and services.",
  },
  {
    title: "Exclusive Student Discounts",
    content:
      "Unlock special rates on courses in social care, safety, mental health, and safeguarding.",
  },
  {
    title: "Save on Essential Expenses",
    content:
      "Take advantage of student discounts to save money on everyday spending.",
  },
  {
    title: "Access to Resources",
    content:
      "Enjoy reduced fees for seminars, workshops, and educational events related to your courses.",
  },
  {
    title: "Enhance Your Learning Experience",
    content:
      "Gain access to valuable discounts on resources and materials essential for your studies.",
  },
];

import axios from "axios";
import { siteConfig } from "./config";
const userAuthToken = (window as any).pluginData.token;
const userId = (window as any).pluginData.user_id;

// check development mode or not
const isDevelopment = process.env.NODE_ENV === "development";
const clientId = isDevelopment ? "97lFsjiCW5t4UTHY" : "Ce93kLNBfvCIBhsC";
export const getUserCourses = async (userToken?: string) => {
  try {
    const response = await axios.post(
      siteConfig.dashboardApiUrl + "/course-info",
      {
        token: userToken || userAuthToken,
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// subscription details

export const getUserSubscriptionDetails = async (userToken?: string) => {
  try {
    const response = await axios.post(
      siteConfig.dashboardApiUrl + "/subscription_details",
      {
        token: userToken || userAuthToken,
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// user recomended courses

export const getUserRecomendedCourses = async (userToken?: string) => {
  try {
    const response = await axios.post(
      siteConfig.dashboardApiUrl + "/recommended-courses",
      {
        token: userToken || userAuthToken,
        user_id: userId,
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// user rewards

export const getUserRewards = async ({ userToken }: { userToken?: string }) => {
  try {
    const response = await axios.post(siteConfig.dashboardApiUrl + "/rewards", {
      token: userToken || userAuthToken,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// leaderboard

export const getLeaderboard = async (
  month: "this_month" | "last_month",
  userToken?: string
) => {
  try {
    const response = await axios.post(
      siteConfig.dashboardApiUrl + "/leaderboard",
      {
        token: userToken || userAuthToken,
        month: month,
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

//achievements

export const getAchievements = async (userToken?: string) => {
  try {
    const response = await axios.post(
      siteConfig.dashboardApiUrl + "/achievements",
      {
        token: userToken || userAuthToken,
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// rewards-by-time-range

export const getRewardsByTimeRange = async ({
  userToken,
  timeRange,
}: {
  userToken?: string;
  timeRange: string;
}) => {
  try {
    const response = await axios.post(
      siteConfig.dashboardApiUrl + "/rewards-by-time-range",
      {
        token: userToken || userAuthToken,
        date_range: timeRange,
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// get all messages

export const getAllMessages = async () => {
  try {
    const response = await axios.get(
      siteConfig.baseApiUrl +
        "/wp/v2/message?_embed&_fields=id,title,content,date"
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// get user certificates

export const getUserCertificates = async (userToken?: string) => {
  try {
    const response = await axios.post(
      siteConfig.dashboardApiUrl + "/certificates",
      {
        token: userToken || userAuthToken,
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// get user course certificate

export const getUserCourseCertificate = async ({
  courseId,
}: {
  courseId: number;
}) => {
  try {
    const response = await axios.post(
      siteConfig.baseApiUrl + "/swca/v1/get-certificate",
      {
        course_id: courseId,
        user_id: userId,
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);

    return null;
  }
};

// get_bundle_category_courses

export const getBundleCategoryCourses = async () => {
  try {
    const response = await axios.post(
      siteConfig.dashboardApiUrl + "/bundle-category-courses",
      {
        token: userAuthToken,
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);

    return null;
  }
};

export const getUserInfo = async () => {
  try {
    const response = await axios.post(
      siteConfig.dashboardApiUrl + "/user-by-token",
      {
        token: userAuthToken,
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);

    return null;
  }
};

// get unlimited learning details

export const getUnlimitedLearningDetails = async () => {
  try {
    const response = await axios.post(
      siteConfig.dashboardApiUrl + "/unlimited-learning-products",
      {
        token: userAuthToken,
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);

    return null;
  }
};

// get woo cart items

export const getWooCartItems = async () => {
  try {
    const response = await axios.get(
      siteConfig.baseApiUrl + `/wc/store/cart?client_id=${clientId}&force`
    );

    return response.data;
  } catch (error) {
    console.log(error);

    return null;
  }
};

// add to cart api

export const addToCart = async ({ productId }: { productId: number }) => {
  try {
    const response = await axios.post(
      siteConfig.baseApiUrl + `/wc/store/v1/cart/add-item`,
      {
        id: productId,
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);

    return null;
  }
};

// generate token

export const generateToken = async () => {
  try {
    const response = await axios.post(
      siteConfig.wplmsApiUrl + "/token/generate-token",
      {
        user_id: userId,
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);

    return null;
  }
};

export const logOut = async () => {
  try {
    const response = await axios.post(siteConfig.dashboardApiUrl + "/logout", {
      token: userAuthToken,
    });

    return response.data;
  } catch (error) {
    console.log(error);

    return null;
  }
};

export const generateCertificate = async (courseId: number) => {
  try {
    const response = await axios.post(
      siteConfig.baseApiUrl + "/swca/v1/generate-certificate",
      {
        course_id: courseId,
        user_id: userId,
        token: userAuthToken,
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);

    return null;
  }
};

export const generateTranscript = async (courseId: number) => {
  try {
    const response = await axios.post(
      siteConfig.baseApiUrl + "/swca/v1/generate-transcript",
      {
        course_id: courseId,
        user_id: userId,
        token: userAuthToken,
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);

    return null;
  }
};

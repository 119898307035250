import {
  Box,
  Button,
  CardActionArea,
  CardActions,
  useTheme,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useMemo } from "react";
import { userCourseTypes } from "../../../types";
import { IUserCourse } from "../../../types/userCourse";
import { courseStatus } from "../../../utils/courseStatus";
import RightArrow from "../icons/RightArrow";
import ContentBaseOnStatus from "./ContentBaseOnStatus";
import parse from "html-react-parser";
export default function MyCourseCard({
  courseInfo,
  courseBtnStatus,
}: {
  courseInfo: IUserCourse;
  courseBtnStatus: userCourseTypes;
}) {
  const theme = useTheme();

  const onHandleCourseView = () => {
    // new tab
    window.open(`/course/${courseInfo.slug}`, "_blank");
  };

  const courseButtonStatus = useMemo(() => {
    if (courseBtnStatus === "enrolled_courses") {
      return courseStatus[courseInfo.course_status] || "Continue Course";
    } else if (courseBtnStatus === "pending_certification") {
      return "Order Certificate";
    } else {
      return "Continue Course";
    }
  }, [courseBtnStatus, courseInfo.course_status]);

  return (
    <Card
      sx={{
        width: {
          xs: "23rem",
          md: "19rem",
        },
        maxWidth: "100%",
        bgcolor: "theme.palette.background.paper",
        // height: "23rem",
        borderRadius: "1rem",
        boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.10)",
        display: "flex",
        flexDirection: {
          xs: "row",
          md: "column",
        },
        justifyContent: "space-between",
        overflow: "hidden",
      }}
    >
      <CardActionArea
        sx={{
          flex: "1",
          display: {
            xs: "flex",
            md: "block",
          },
        }}
      >
        <CardMedia
          component="img"
          sx={{
            objectFit: "cover",
            width: {
              xs: "8rem",
              md: "100%",
            },
            height: {
              xs: "100%",
              md: "12.5rem",
            },
          }}
          src={courseInfo.featured_image.toString() ?? ""}
          alt={courseInfo.title}
        />
        <CardContent
          sx={{
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
            flex: "1",
            p: {
              xs: "0.5rem 0 0 0.5rem",
              md: "1rem",
            },
            gap: "1rem",
          }}
        >
          <Typography
            gutterBottom
            variant="h6"
            // text color changed depending on theme
            sx={{
              color: theme.palette.mode === "light" ? "text.primary" : "white",
              fontSize: {
                xs: "0.875rem",
                md: "1.125rem",
              },
              // max line 2
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              height: "3.5rem",
            }}
          >
            {parse(courseInfo.title)}
          </Typography>
          <ContentBaseOnStatus
            courseInfo={courseInfo}
            courseBtnStatus={courseBtnStatus}
          />
          <Box
            sx={{
              width: "100%",
              display: {
                xs: "flex",
                md: "none",
              },
              justifyContent: "flex-end",
            }}
          >
            <Button
              startIcon={
                <RightArrow
                  stroke={
                    courseBtnStatus === "enrolled_courses" ? "#F6F6FA" : "black"
                  }
                />
              }
              component="a"
              onClick={onHandleCourseView}
              sx={{
                fontSize: "0.75rem",
                color:
                  courseBtnStatus === "enrolled_courses"
                    ? "white"
                    : "primary.400",
                bgcolor:
                  courseBtnStatus === "enrolled_courses"
                    ? "primary.400"
                    : "secondary.400",
                height: "2.5rem",
                borderTopLeftRadius: "1rem",
                px: "1rem",
                "&:hover": {
                  bgcolor:
                    courseBtnStatus === "enrolled_courses"
                      ? "primary.600"
                      : "secondary.600",
                },
              }}
            >
              {courseStatus[courseInfo.course_status]}
            </Button>
          </Box>
        </CardContent>
      </CardActionArea>
      <CardActions
        sx={{
          mx: "0.5rem 0",
          p: "0",
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        <Button
          size="large"
          color="primary"
          variant="contained"
          sx={{
            borderRadius: "0",
            height: "3rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: courseBtnStatus != "enrolled_courses" ? "black" : "white",
            width: "100%",
            bgcolor:
              courseBtnStatus != "enrolled_courses"
                ? "secondary.400"
                : "primary.400",
            "&:hover": {
              bgcolor:
                courseBtnStatus != "enrolled_courses"
                  ? "secondary.600"
                  : "primary.600",
            },
          }}
          endIcon={
            <RightArrow
              stroke={
                courseBtnStatus != "enrolled_courses" ? "black" : "#F6F6FA"
              }
            />
          }
          onClick={onHandleCourseView}
        >
          {courseButtonStatus}
        </Button>
      </CardActions>
    </Card>
  );
}

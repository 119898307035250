import { Box, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import wevImage from "../../../assets/images/wave.png";
import { IUserInfo } from "../../../types";
import { IUserCourseInfo } from "../../../types/userCourse";
import MyCourseList from "../../Common/MyCourseList";
import UserCourseInfo from "../../Common/UserCourseInfo";
import CSTSCARD from "./CSTSCARD";
import RecommendedCourses from "./RecommendedCourses";
import UserRewards from "./UserRewards";

const Dashboard = ({ courseInfo }: { courseInfo: IUserCourseInfo }) => {
  const { data: userInformation } = useQuery<IUserInfo>({
    queryKey: ["userInformation"],
  });

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      rowGap={"2rem"}
      paddingBottom={"2rem"}
    >
      <Box display={"flex"} alignItems={"center"} gap={"1rem"}>
        <img
          src={wevImage}
          style={{
            width: "2rem",
            height: "2rem",
          }}
          alt="wevImage"
        />
        <Typography variant="h4" lineHeight={2}>
          Hello{" "}
          <Typography
            color="primary.main"
            component={"span"}
            variant="h3"
            fontWeight={"bold"}
            textTransform={"capitalize"}
          >
            {" "}
            {userInformation?.displayname}
          </Typography>
          , Welcome to CareSkills Training
        </Typography>
      </Box>
      <UserCourseInfo
        courseInfo={{
          enrolled_courses: courseInfo.enrolled_courses,
          inprogress_courses: courseInfo.inprogress_courses,
          complete_courses: courseInfo.complete_courses,
          course_certificates: courseInfo.course_certificates,
        }}
      />
      <UserRewards
        total_rewards={courseInfo.total_rewards}
        login_day_count={courseInfo.login_day_count}
      />
      <CSTSCARD />
      <RecommendedCourses />
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: "1.5rem",
          color: "#2E323E",
        }}
        marginBottom={"1rem"}
      >
        Recently Enrolled Courses
      </Typography>
      <MyCourseList
        userCourses={courseInfo.enrolled_courses}
        courseStatus="enrolled_courses"
      />
    </Box>
  );
};

export default Dashboard;

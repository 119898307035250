import { SVGProps } from "react";

const BookIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      {...props}
    >
      <path
        d="M4.16666 7.37902C5.22148 7.54238 6.39815 7.81396 7.5 8.24005M4.16666 10.7124C4.69957 10.7949 5.26358 10.905 5.83333 11.0488M10 5.54463V17.4189M3.3286 3.00955C5.17725 3.21844 7.66094 3.78042 9.43068 5.02052C9.77082 5.25886 10.2292 5.25886 10.5693 5.02052C12.3391 3.78042 14.8227 3.21844 16.6714 3.00955C17.5863 2.90616 18.3333 3.67017 18.3333 4.6128V14.0001C18.3333 14.9427 17.5863 15.707 16.6714 15.8104C14.8227 16.0193 12.3391 16.5813 10.5693 17.8214C10.2292 18.0597 9.77082 18.0597 9.43068 17.8214C7.66094 16.5813 5.17725 16.0193 3.3286 15.8104C2.41368 15.707 1.66666 14.9427 1.66666 14.0001V4.6128C1.66666 3.67017 2.41368 2.90616 3.3286 3.00955Z"
        stroke="#73828A"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default BookIcon;

import { SVGProps } from "react";

const RightArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ?? "17"}
    height={props.height ?? "20"}
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      d="M10.5 6.66656L13.8333 9.99987M13.8333 9.99987L10.5 13.3332M13.8333 9.99987H2.16663"
      stroke={props.stroke ?? "#F6F6FA"}
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 15.2043C9.14133 16.1193 10.5901 16.6665 12.1667 16.6665C15.8486 16.6665 18.8333 13.6818 18.8333 9.99986C18.8333 6.31796 15.8486 3.33319 12.1667 3.33319C10.5901 3.33319 9.14133 3.88045 8 4.79538"
      stroke={props.stroke ?? "#F6F6FA"}
      stroke-width="1.25"
      stroke-linecap="round"
    />
  </svg>
);

export default RightArrow;

// Course status : 1 : START COURSE
// Course status : 2 : CONTINUE COURSE
// Course status : 3 : FINISH COURSE : COURSE UNDER EVALUATION
// Course status : 4 : COURSE EVALUATED

export const courseStatus = {
  "1": "Start Course",
  "2": "Continue Course",
  "3": "Finish Course",
  "4": "Course Evaluated",
};

export type CourseStatus = keyof typeof courseStatus;

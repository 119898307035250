import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { IUserCourse } from "../../../types/userCourse";
import ContentTitle from "../../Common/ContentTitle";
import MyCourseList from "../../Common/MyCourseList";
import { useMemo, useState } from "react";
import { ExpandMore } from "@mui/icons-material";
import DashboardNumbers from "../../Common/DashboardNumbers";
import { userCourseTypes } from "../../../types";

const MyCourses = ({
  userCorseInfoList,
}: {
  userCorseInfoList: {
    enrolled_courses: IUserCourse[];
    complete_courses: IUserCourse[];
    inprogress_courses: IUserCourse[];
    course_certificates: any[];
  };
}) => {
  const [value, setValue] = useState<userCourseTypes>("enrolled_courses");
  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: userCourseTypes
  ) => {
    setValue(newValue);
  };

  const pendingCertification = useMemo(() => {
    return userCorseInfoList.enrolled_courses.filter(
      (course) => !course.certificate_url
    );
  }, [userCorseInfoList.enrolled_courses]);
  const myCourseTabs = [
    {
      label: "Enrolled Courses",
      value: "enrolled_courses",
      count: userCorseInfoList.enrolled_courses.length,
      courses: userCorseInfoList.enrolled_courses,
    },
    {
      label: "Active Courses",
      value: "inprogress_courses",
      count: userCorseInfoList.inprogress_courses.length,
      courses: userCorseInfoList.inprogress_courses,
    },
    {
      label: "Complete Courses",
      value: "complete_courses",
      count: userCorseInfoList.complete_courses.length,
      courses: userCorseInfoList.complete_courses,
    },
    {
      label: "Pending Certification",
      value: "pending_certification",
      count: pendingCertification.length,
      courses: pendingCertification,
    },
  ];
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      rowGap={"2rem"}
      paddingBottom={"2rem"}
    >
      <ContentTitle title="MyCourses" />
      {/* <UserCourseInfo courseInfo={userCorseInfoList} /> */}
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "flex",
          },
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {/* <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="secondary tabs example"
          sx={{ marginBottom: "1rem" }}
        >
          {myCourseTabs.map((item) => {
            return (
              <Tab
                key={item.value}
                label={`${item.label} (${item.count})`}
                value={item.value}
                sx={{
                  textTransform: "capitalize",

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "14rem",
                  height: "3.375rem",
                  fontSize: "1.125rem",
                  bgcolor:
                    theme.palette.mode === "dark" ? "#2E323E" : "#F6F6FA",

                  "&.Mui-selected": {
                    bgcolor: "#fff",
                    color: "primary.400",
                    fontWeight: 600,
                  },
                }}
              />
            );
          })}
        </Tabs> */}
        {
          <DashboardNumbers
            numbers={myCourseTabs.map((item) => {
              return {
                value: item.value,
                label: item.label,
                count: item.count,
              };
            })}
            activeValue={value}
            handleActive={handleChange}
          />
        }
        {myCourseTabs.map((item) => {
          return (
            <Box
              key={item.value}
              sx={{
                display: value === item.value ? "block" : "none",
              }}
            >
              <MyCourseList userCourses={item.courses} courseStatus={value} />
            </Box>
          );
        })}
      </Box>

      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        {myCourseTabs.map((item) => {
          return (
            <Accordion key={item.value} defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>{`${item.label} (${item.count})`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <MyCourseList userCourses={item.courses} courseStatus={value} />
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </Box>
  );
};

export default MyCourses;

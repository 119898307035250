import { Paper, Typography } from "@mui/material";
import { getUserRecomendedCourses } from "../../../utils/apiCall";

import { useQuery } from "@tanstack/react-query";
import { IUserCourse } from "../../../types/userCourse";
import MyCourseList from "../../Common/MyCourseList";

const RecommendedCourses = () => {
  const { data: recommendedCourses } = useQuery<IUserCourse[]>({
    queryKey: ["recommendedCourses"],
    queryFn: () => getUserRecomendedCourses(),
  });
  console.log(recommendedCourses);
  return (
    <Paper
      sx={{
        padding: "2rem",
        borderRadius: "1rem",
        marginTop: "2rem",
      }}
      elevation={26}
    >
      <Typography variant="h4" marginBottom={"1rem"} fontWeight={"500"}>
        Recommended Courses for You
      </Typography>
      <MyCourseList
        userCourses={recommendedCourses ?? []}
        courseStatus={"enrolled_courses"}
      />
    </Paper>
  );
};

export default RecommendedCourses;

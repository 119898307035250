// CertificateCard.tsx
import { generateCertificate } from "../../../utils/apiCall";
import DocumentCard from "../../Common/DocumentCard";
import { IDocument } from "../../../types/userCourse";

const CertificateCard = ({
  certificate,
  activeCertificate,
}: {
  certificate: IDocument;
  activeCertificate: "available" | "claimed" | "pending";
}) => {
  return (
    <DocumentCard
      document={certificate}
      activeDocument={activeCertificate}
      onGenerate={generateCertificate}
      documentType="Certificate"
    />
  );
};

export default CertificateCard;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContentTitle from "../../Common/ContentTitle";
import DashboardNumbers from "../../Common/DashboardNumbers";
import { IDocument } from "../../../types/userCourse";
import CertificateList from "./CertificateList";
import { useContext, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import AppContext from "../../../contexts/AppContext";
import { getUserCertificates } from "../../../utils/apiCall";

const MyCertificates = () => {
  const userToken = useContext(AppContext).token;
  const { data: certificateList, isLoading } = useQuery<IDocument[]>({
    queryKey: ["certificateList"],
    queryFn: () => getUserCertificates(userToken),
    enabled: !!userToken,
  });

  const [activeNumber, setActiveNumber] = useState<
    "available" | "claimed" | "pending"
  >("claimed");

  const displayCertificates = useMemo(() => {
    let filteredCertificates: IDocument[] = [];
    const availableCertificates = certificateList?.filter((certificate) => {
      return (
        !certificate.certificate_url &&
        certificate.is_certificate_unlocked &&
        parseInt(certificate.course_progress) > 99
      );
    });
    const claimedCertificate = certificateList?.filter((certificate) => {
      return certificate.certificate_url;
    });

    const pendingCertification = certificateList?.filter((certificate) => {
      return !certificate.certificate_url;
    });

    if (activeNumber === "available") {
      filteredCertificates = availableCertificates || [];
    } else if (activeNumber === "claimed") {
      filteredCertificates = claimedCertificate || [];
    } else {
      filteredCertificates = pendingCertification || [];
    }

    return {
      availableCertificates: availableCertificates || [],
      claimedCertificate: claimedCertificate || [],
      filteredCertificates,
      pendingCertification: pendingCertification || [],
    };
  }, [activeNumber, certificateList]);

  const handleNumberClick = (
    _event: React.SyntheticEvent,
    newValue: "available" | "claimed" | "pending"
  ) => {
    setActiveNumber(newValue);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      rowGap={"2rem"}
      paddingBottom={"2rem"}
    >
      <ContentTitle title="My Certificates" />
      <Box
        sx={{
          display: {
            xs: "none",
            md: "flex",
          },
          flexDirection: "column",
        }}
      >
        <DashboardNumbers
          numbers={[
            {
              label: "Available Certificates",
              count: displayCertificates.availableCertificates.length,
              value: "available",
            },
            {
              label: "Claimed Certificates",
              count: displayCertificates.claimedCertificate.length,
              value: "claimed",
            },
            {
              label: "Pending Certification",
              count: displayCertificates.pendingCertification.length,
              value: "pending",
            },
          ]}
          activeValue={activeNumber}
          handleActive={handleNumberClick}
        />

        <CertificateList
          displayCertificates={displayCertificates.filteredCertificates}
          activeNumber={activeNumber}
        />
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              sx={{
                fontSize: "1.25rem",
                fontWeight: "bold",
              }}
            >{`Available Certificates (${displayCertificates.availableCertificates.length})`}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CertificateList
              displayCertificates={displayCertificates.availableCertificates}
              activeNumber={activeNumber}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              sx={{
                fontSize: "1.25rem",
                fontWeight: "bold",
              }}
            >
              {" "}
              {`Claimed Certificates (${displayCertificates.claimedCertificate.length})`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CertificateList
              displayCertificates={displayCertificates.claimedCertificate}
              activeNumber={activeNumber}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default MyCertificates;

import { Box, Button, Typography } from "@mui/material";
import RightArrow from "../../Common/icons/RightArrow";

const SingleBundle = ({ singleBundle }: { singleBundle: any }) => {
  return (
    <Box
      sx={{
        backgroundColor: { xs: "white", sm: "#F6F6FA" },
        borderRadius: "1rem",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        overflow: "hidden",
        flexWrap: {
          xs: "wrap",
          sm: "nowrap",
        },
      }}
    >
      <Box
        display={"grid"}
        maxWidth={{ xs: "100%", sm: "40%" }}
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "1rem",
          margin: { xs: "auto", sm: "1rem" },
          borderRadius: "0.5rem",
          backgroundColor: "primary.50",
          padding: "0.5rem",
        }}
      >
        {singleBundle.included_courses.map((course: any, index: number) => {
          // show only 4 courses images
          if (index > 3) return null;

          return (
            <Typography
              key={course.course_id}
              component={"img"}
              src={course?.course_image}
              alt={course?.course_title}
              sx={{
                height: "8.25rem",
                borderRadius: "0.25rem",
              }}
            />
          );
        })}
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        mt={"2rem"}
        width={{ xs: "100%", sm: "60%" }}
      >
        <Typography
          sx={{
            color: "black",
            fontSize: "1.125rem",
            fontWeight: 700,
          }}
        >
          {singleBundle.post_title}
        </Typography>
        <Box
          display={"flex"}
          flexDirection={"column"}
          m={{
            xs: "0.5rem 0",
            sm: "1rem 0",
          }}
        >
          <Typography
            variant="subtitle2"
            component={"ul"}
            sx={{
              listStyle: "inside",
              paddingLeft: "0",
              color: "grey.400",
              fontSize: "0.875rem",
            }}
          >
            The Essential All Rounder Bundle contains one of each:
            {singleBundle.included_courses.map((course: any, index: number) => {
              // show only 3 courses
              if (index > 1) return null;
              return (
                <Typography
                  key={course.course_id}
                  component={"li"}
                  sx={{
                    color: "grey.400",
                    fontSize: "0.875rem",
                    marginLeft: "0.5rem",
                  }}
                >
                  {course.course_title}
                </Typography>
              );
            })}
          </Typography>
          {singleBundle.included_courses.length > 6 && (
            <Typography
              variant="subtitle2"
              sx={{
                color: "grey.500",
                fontSize: "0.875rem",
              }}
            >
              and {singleBundle.included_courses.length - 6} more courses
            </Typography>
          )}
        </Box>

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={{
            xs: "column",
            sm: "row",
          }}
          gap={"0.5rem"}
        >
          <Typography
            variant="subtitle1"
            sx={{
              color: "gray.400",
              textDecoration: "line-through 1px",
              display: "inline-block",
              m: "auto 0",
              fontWeight: 700,
            }}
          >
            £{singleBundle.regular_price}
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              bgcolor: "secondary.100",
              color: "black",
              display: "inline-block",
              p: "0.25rem 1rem",
              width: "fit-content",
              height: "fit-content",
              m: "auto 0",
              fontWeight: 700,
              borderRadius: "0.25rem",
            }}
          >
            £{singleBundle.sale_price}
          </Typography>

          <Button
            variant="contained"
            sx={{
              bgcolor: "primary.50",
              color: "primary.main",
              borderRadius: {
                xs: "0.5rem",
                sm: "1rem 0 0 0",
              },
              width: {
                xs: "100%",
                sm: "10rem",
              },
              height: "3.5rem",
              boxShadow: "none",
              fontWeight: 500,
              "&:hover": {
                color: "white",
                "& path": {
                  stroke: "white",
                },
              },
            }}
            target="_blank"
            href={`/course/${singleBundle.post_name}`}
            startIcon={<RightArrow stroke="rgba(15, 33, 125, 1)" />}
          >
            View Bundle
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SingleBundle;

import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import "./App.css";
import RootComponent from "./components/RootComponent/RootComponent";
import AppProvider from "./contexts/AppProvider";
import useCustomTheme from "./hooks/useCustomTheme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
});
// Create a client
const queryClient = new QueryClient();
export default function ToggleColorMode() {
  const { theme, colorMode } = useCustomTheme();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <QueryClientProvider client={queryClient}>
        <AppProvider>
          <ThemeProvider theme={theme}>
            <RootComponent />
          </ThemeProvider>
        </AppProvider>
      </QueryClientProvider>
    </ColorModeContext.Provider>
  );
}

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Diversity2SharpIcon from "@mui/icons-material/Diversity2Sharp";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import BadgeIcon from "@mui/icons-material/Badge";
import AutoStoriesSharpIcon from "@mui/icons-material/AutoStoriesSharp";
import SchoolSharpIcon from "@mui/icons-material/SchoolSharp";
import DescriptionIcon from "@mui/icons-material/Description";
import { allTabList } from "../../../utils/data";
function a11yProps(index: string) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const IconOfTabs = (props: { icon: JSX.Element; open: boolean }) => {
  return (
    <Box
      sx={{
        mr: props.open ? 1 : 3,
        color: "primary.contrastText",
      }}
    >
      {props.icon}
    </Box>
  );
};

const AllTabs = ({
  value,
  handleChange,
  sideBarOpen,
}: {
  value: string;
  handleChange: (newValue: string) => void;
  sideBarOpen: boolean;
}) => {
  const tabIconMap: { [key: string]: JSX.Element } = {
    dashboard: <IconOfTabs open={sideBarOpen} icon={<DashboardIcon />} />,
    "my-courses": (
      <IconOfTabs open={sideBarOpen} icon={<AutoStoriesSharpIcon />} />
    ),
    "my-certificate": (
      <IconOfTabs open={sideBarOpen} icon={<SchoolSharpIcon />} />
    ),
    "student-card": <IconOfTabs open={sideBarOpen} icon={<BadgeIcon />} />,
    "my-rewards": <IconOfTabs open={sideBarOpen} icon={<EmojiEventsIcon />} />,
    "unlimited-learning": (
      <IconOfTabs open={sideBarOpen} icon={<LocalLibraryIcon />} />
    ),
    "bundle-courses": (
      <IconOfTabs open={sideBarOpen} icon={<Diversity2SharpIcon />} />
    ),
    "special-offers": (
      <IconOfTabs open={sideBarOpen} icon={<LocalOfferIcon />} />
    ),
    "my-transcript": (
      <IconOfTabs open={sideBarOpen} icon={<DescriptionIcon />} />
    ),
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "primary.main",
        display: "flex",
        height: 224,
        width: "100%",
        pt: "1.5rem",
      }}
    >
      <Tabs
        orientation="vertical"
        value={value}
        onChange={(_, newValue) => handleChange(newValue)}
        indicatorColor="secondary"
        sx={{
          height: "100%",
          borderRight: 1,
          borderColor: "divider",
          width: "100%",

          "& .MuiTabs-flexContainer": {
            justifyContent: "flex-start",
            width: "100%",
            alignItems: "start",
          },
          "& .MuiTabs-scroller ": {
            overflowY: "auto !important",
            "&::-webkit-scrollbar": {
              width: "0.5rem",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "0.5rem",
            },

            "&::--webkit-scrollbar-track": {
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
            },
          },
        }}
      >
        {allTabList.map((tab) => {
          return (
            <Tab
              key={tab.slug}
              iconPosition="start"
              label={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    color: "primary.contrastText",
                    textTransform: "capitalize",
                  }}
                >
                  {tab.label}
                </Box>
              }
              icon={tabIconMap[tab.slug]}
              {...a11yProps(tab.slug)}
              value={tab.slug}
              sx={{
                minHeight: "3.5rem",
                marginLeft: sideBarOpen ? "1rem" : "0",
                width: sideBarOpen ? "80%" : "auto",
                justifyContent: "flex-start",
                gap: "1rem",
                "&.Mui-selected": {
                  bgcolor: "rgba(255, 255, 255, 0.10)",
                  borderRadius: "0.5rem",
                },
              }}
            />
          );
        })}
      </Tabs>
    </Box>
  );
};

export default AllTabs;



import { createContext } from 'react';
import { AppState } from '../types';

// Add the missing import for AppContextType


const AppContext = createContext<AppState>({
    token: "",
    subscription_info: null,
    loading: false,

});
export default AppContext;

import { Box, Typography } from "@mui/material";
import idCard from "../../../assets//images/dashboard-student-id-card.webp";
import bulletPoint from "../../../assets//images/bullet-point.svg";
import { idCardBenefits } from "../../../utils/data";
import CommonButton from "../../Common/buttons/CommonButton";

const StudentCard = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column-reverse", md: "row" },
        gap: "3rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          width: {
            xs: "100%",
            md: "70%",
          },
          maxWidth: "60rem",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: "1.5rem", md: "2rem", lineHeight: "1.8" },
          }}
          fontWeight={"600"}
        >
          Transform Your Study Experience with <br />
          Our{" "}
          <Typography
            component={"span"}
            sx={{
              bgcolor: "secondary.50",
              borderRadius: "2rem",
              padding: "0.5rem 1.5rem",
              m: "0 !important",
              fontSize: { xs: "1.5rem", md: "2rem" },
              fontWeight: "600",
            }}
          >
            Student ID Card
          </Typography>
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "grey.300", fontSize: "1.125rem", fontWeight: "700" }}
        >
          A world of privileges awaits!
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: "grey.300", fontSize: "1.125rem" }}
        >
          With our personalized Student ID card, you can enjoy exclusive
          benefits and gifts to enrich your learning journey further. From
          health and social care, safety and safeguarding to mental health and
          Work-life balance, you are the first in line to receive
        </Typography>
        {idCardBenefits.map((benefit) => (
          <Box
            key={benefit.title}
            sx={{
              display: "flex",
              gap: "1.5rem",
            }}
          >
            <Typography
              component={"img"}
              src={bulletPoint}
              sx={{ width: "1.5rem", height: "1.5rem" }}
              alt="bulletPoint"
            />

            <Typography
              sx={{
                fontWeight: "700",
                color: "grey.300",
                fontSize: { xs: "1rem", md: "1.125rem" },
                flex: 1,
              }}
            >
              {benefit.title} : &nbsp;
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "500",
                  color: "grey.300",
                  display: "inline",
                  fontSize: { xs: "1rem", md: "1.125rem" },
                  textWrap: "wrap",
                }}
              >
                {benefit.content}
              </Typography>
            </Typography>
          </Box>
        ))}
        <CommonButton
          buttonText="Order now"
          buttonStyles={{
            width: "13.75rem",
            height: "3.75rem",
            borderRadius: "2rem",
            mt: "2rem",
          }}
          linkUrl="/order-student-id-card"
        />{" "}
      </Box>
      <Box
        sx={{
          display: "flex",
          width: {
            xs: "100%",
            md: "30%",
          },
        }}
      >
        <Typography
          component={"img"}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
          src={idCard}
          alt="student-id-card"
        />
      </Box>
    </Box>
  );
};

export default StudentCard;

import { Box, Paper, Stack, Typography } from "@mui/material";
import CommonButton from "../../Common/buttons/CommonButton";
import { ILearningProduct } from "../../../types";
import { compareList } from "../../../utils/data";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelIcon from "@mui/icons-material/Cancel";
const PersonalUnlimitedLearning = ({
  primeProduct,
  lifetimeProduct,
}: {
  primeProduct: ILearningProduct | undefined;
  lifetimeProduct: ILearningProduct | undefined;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        width: {
          xs: "100%",
          md: "50%",
        },
      }}
    >
      <Paper
        sx={{
          padding: "2.5rem",
          margin: "1rem",
          borderRadius: "1.5rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          boxShadow: "0px 2px 12px 0px rgba(20, 20, 43, 0.08)",
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          Prime
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "grey.300" }}>
          Access all our healthcare and mandatory compliance courses for a full
          year.
        </Typography>

        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          £{primeProduct?.price}{" "}
          <span
            style={{
              color: "#73828A",
              fontSize: "1.125rem",
              fontWeight: "normal",
            }}
          >
            per year, billed annually
          </span>
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          gap={{
            xs: "0",
            md: "1rem",
          }}
          alignItems={"center"}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "500",
              color: "grey.300",
              fontSize: "1.125rem",
            }}
          >
            Normally{" "}
            <span style={{ textDecoration: "line-through" }}>
              £{primeProduct?.regular_price}
            </span>
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "700",
              color: "white",
              fontSize: "1.125rem",
              bgcolor: "secondary.main",
              borderRadius: "2rem",
              padding: "0.5rem 1rem",
              m: "0 !important",
            }}
          >
            Save {primeProduct?.discount_percent}%
          </Typography>
        </Stack>
        <CommonButton
          buttonText="Get it now"
          hasButtonIcon={true}
          linkUrl={`/cart/?add-to-cart=${primeProduct?.id}`}
        />
        {/* what's include */}
        <Box
          sx={{
            display: {
              xs: "flex",
              md: "none",
            },
            flexDirection: "column",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "500", color: "white" }}>
            What's included
          </Typography>
          {compareList.map((row) => (
            <Stack
              direction="row"
              key={row.title}
              spacing={1}
              sx={{
                display: "flex",
                alignItems: "center",
                color: "white",
              }}
            >
              {row.prime ? (
                <CheckCircleRoundedIcon color="secondary" />
              ) : (
                <CancelIcon color="error" />
              )}
              <Typography variant="body1" color={"grey.500"}>
                {row.title}
              </Typography>
            </Stack>
          ))}
        </Box>
      </Paper>
      <Paper
        sx={{
          padding: "2.5rem",
          margin: "1rem",
          borderRadius: "1.5rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          backgroundColor: "primary.400",
          color: "white",
          boxShadow: "0px 2px 12px 0px rgba(20, 20, 43, 0.08)",
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "bold", color: "white" }}>
          {lifetimeProduct?.title}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "white", opacity: "0.7" }}>
          {lifetimeProduct?.short_description}
        </Typography>

        <Typography variant="h4" sx={{ fontWeight: "bold", color: "white" }}>
          £{lifetimeProduct?.price}{" "}
          <span
            style={{
              fontSize: "1.125rem",
              fontWeight: "normal",
              color: "white",
              opacity: "0.7",
            }}
          >
            only
          </span>
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          gap={{
            xs: "0",
            md: "1rem",
          }}
          alignItems={"center"}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "500",
              color: "white",
              opacity: "0.7",
              fontSize: "1.125rem",
            }}
          >
            Normally{" "}
            <span style={{ textDecoration: "line-through" }}>
              £{lifetimeProduct?.regular_price}
            </span>
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "700",
              color: "white",
              fontSize: "1.125rem",
              bgcolor: "secondary.main",
              borderRadius: "2rem",
              padding: "0.5rem 1rem",
              m: "0 !important",
            }}
          >
            Save {lifetimeProduct?.discount_percent}%
          </Typography>
        </Stack>
        <CommonButton
          buttonText="Get it now"
          hasButtonIcon={true}
          linkUrl={`/cart/?add-to-cart=${lifetimeProduct?.id}`}
          buttonStyles={{ bgcolor: "white", color: "primary.400" }}
          endIconStrokeColor="#3F4D97"
        />
        {/* what's include */}
        <Box
          sx={{
            display: {
              xs: "flex",
              md: "none",
            },
            flexDirection: "column",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "500", color: "white" }}>
            What's included
          </Typography>
          {compareList.map((row) => (
            <Stack
              direction="row"
              key={row.title}
              spacing={1}
              sx={{
                display: "flex",
                alignItems: "center",
                color: "white",
              }}
            >
              {row.lifetime ? (
                <CheckCircleRoundedIcon color="secondary" />
              ) : (
                <CancelIcon color="error" />
              )}
              <Typography variant="body1" color={"white"}>
                {row.title}
              </Typography>
            </Stack>
          ))}
        </Box>
      </Paper>
    </Box>
  );
};

export default PersonalUnlimitedLearning;

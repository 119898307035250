import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { accordionList } from "../../utils/data";

const CommonAccordion = () => {
  return (
    <div>
      {accordionList.map((accordion) => {
        return (
          <Accordion
            key={accordion?.id}
            sx={{
              borderRadius: "0.5rem",
              marginBottom: "1rem",
              bgcolor: "#F6F6FA",
              border: "none",
              "&:before": {
                display: "none",
              },
            }}
            defaultExpanded={accordion?.expanded}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${accordion?.id}-content`}
              id={`${accordion?.id}-header`}
            >
              <Typography>{accordion?.title}</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: "white",
              }}
            >
              <Typography>{accordion?.content}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default CommonAccordion;

export const siteConfig = {
  baseApiUrl: window.location.origin + "/wp-json",
  dashboardApiUrl: window.location.origin + "/wp-json/sa-rest-api/v1",
  wplmsApiUrl: window.location.origin + "/vibebp/v1",
  siteUrl: window.location.origin,
  siteName: "Student Dashboard",
  client_id: "97lFsjiCW5t4UTHY&nocache",
};

// http://wplms-careskill.local/wp-json/vibebp/v1/token/generate-token/?client_id=97lFsjiCW5t4UTHY&nocache

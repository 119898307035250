import {
  Box,
  FormControlLabel,
  styled,
  Switch,
  SwitchProps,
  Typography,
} from "@mui/material";
import { getUnlimitedLearningDetails } from "../../../utils/apiCall";
import { useQuery } from "@tanstack/react-query";
import { ILearningProduct } from "../../../types";
import PersonalUnlimitedLearning from "./PersonalUnlimitedLearning";
import ProductCompare from "./ProductCompare";
import { useState } from "react";
import TeamUnlimitedLearning from "./TeamUnlimitedLearning";
const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 48,
  height: 28,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.secondary.main,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const UnlimitedLearning = () => {
  const [personal, setPersonal] = useState(true);
  const { data: unlimitedLearning, isLoading } = useQuery<ILearningProduct[]>({
    queryKey: ["userLearning"],
    queryFn: () => getUnlimitedLearningDetails(),
  });

  console.log(unlimitedLearning);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Box>
        <Typography
          variant="h1"
          sx={{
            fontSize: {
              xs: "2rem",
              md: "3rem",
            },
            fontWeight: "bold",
            color: "#333333",
            textAlign: "center",
            marginBottom: "1rem",
            background: "linear-gradient(180deg, #3F4D97 0%, #16C2D5 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Unlimited Course Access for you or your team
        </Typography>

        <Typography
          variant="subtitle1"
          sx={{ textAlign: "center", color: "grey.300" }}
        >
          Free Certificates, Transcripts, Student ID Card and Many More
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <FormControlLabel
          control={
            <IOSSwitch
              sx={{ m: 1 }}
              defaultChecked
              onChange={(e) => setPersonal(e.target.checked)}
            />
          }
          label="Personal"
          sx={{
            position: "relative",
            fontWeight: "bold",
            my: "1.5rem",
            "& .MuiFormControlLabel-label": {
              color: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[800]
                  : theme.palette.grey[300],
              fontSize: "1rem",
              fontWeight: "bold",
            },
            "&::before": {
              content: '"Team"',
              position: "absolute",
              right: "100%",
              top: "50%",
              transform: "translateY(-50%)",
              color: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[800]
                  : theme.palette.grey[300],
              fontSize: "1rem",
            },
          }}
        />
        <Box sx={{ display: "flex", width: "100%" }}>
          <ProductCompare />
          {personal ? (
            <PersonalUnlimitedLearning
              primeProduct={unlimitedLearning?.[2]}
              lifetimeProduct={unlimitedLearning?.[3]}
            />
          ) : (
            <TeamUnlimitedLearning
              bulkCourseProduct={unlimitedLearning?.[1]}
              teamLSubscriptionProduct={unlimitedLearning?.[0]}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default UnlimitedLearning;

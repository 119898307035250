import { Box, Grid } from "@mui/material";
import MyCourseCard from "./courseCard/MyCourseCard";
import { IUserCourse } from "../../types/userCourse";
import { userCourseTypes } from "../../types";

const MyCourseList = ({
  userCourses,
  courseStatus,
}: {
  userCourses: IUserCourse[];
  courseStatus: userCourseTypes;
}) => {
  if (userCourses?.length === 0) {
    return (
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <h1>No Courses Found</h1>
      </Box>
    );
  }
  return (
    <Grid
      container
      spacing={4}
      alignItems={"center"}
      justifyContent={{
        xs: "center",
        sm: "center",
        md: "flex-start",
      }}
    >
      {userCourses?.map((item) => {
        return (
          <Grid item key={item.slug}>
            <MyCourseCard courseInfo={item} courseBtnStatus={courseStatus} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default MyCourseList;

import demoCertificate from "../../../assets/images/mock-cs-ts.png";
import idCard from "../../../assets/images/CST-ID-Card.png";
import CommonButton from "../../Common/buttons/CommonButton";
import { Box, Typography } from "@mui/material";

const CSTSCARD = () => {
  return (
    <Box
      display={"flex"}
      gap={"1rem"}
      sx={{
        flexWrap: "wrap",
      }}
    >
      <Box
        display={"flex"}
        sx={{
          padding: "1rem 2rem 1rem 1rem",
          borderRadius: "1rem",
          background: "#F6F6FA",
          gap: "1rem",
          maxWidth: "37rem",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            maxWidth: "15.5rem",
          }}
          component={"img"}
          src={demoCertificate}
          alt={"icon"}
        />
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"0.5rem"}
          my={"auto"}
          alignItems={{ xs: "center", sm: "flex-start" }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "1.125rem",
            }}
          >
            Order Your Accredited
          </Typography>
          <Typography
            variant="h6"
            sx={{
              bgcolor: "secondary.100",
              borderRadius: "1rem",
              fontSize: "1.125rem",
              textAlign: "center",
              px: "1rem",
              py: "0.25rem",
            }}
          >
            Certificate & Transcript
          </Typography>

          <Typography
            variant="h6"
            sx={{
              fontSize: "1.125rem",
            }}
          >
            & Get Hardcopy the next day
          </Typography>
          <CommonButton
            onHandleClick={() => {}}
            buttonText="Get PDF Certificate Now"
            hasButtonIcon={false}
            buttonStyles={{
              width: "15.5rem",
            }}
          />
        </Box>
      </Box>
      <Box
        display={"flex"}
        sx={{
          padding: "1rem",
          borderRadius: "1rem",
          background: "#F6F6FA",
          gap: "1rem",
          maxWidth: "37rem",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            maxWidth: "15.5rem",
          }}
          component={"img"}
          src={idCard}
          alt={"icon"}
        />
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"0.5rem"}
          my={"auto"}
          alignItems={"flex-start"}
        >
          <Box display={"flex"} gap={"0.5rem"}>
            <Typography
              variant="h6"
              sx={{
                fontSize: "1.125rem",
              }}
            >
              Get Your
            </Typography>
            <Typography
              variant="h6"
              sx={{
                bgcolor: "secondary.100",
                px: "1rem",
                py: "0.25rem",
                borderRadius: "1rem",
                fontSize: "1.125rem",
                textAlign: "center",
              }}
            >
              Student ID Card
            </Typography>
          </Box>

          <Typography
            variant="body2"
            sx={{
              maxWidth: "15rem",
              textAlign: {
                xs: "center",
                sm: "left",
              },
            }}
          >
            Order now to get a discount and claim when you finish your course
          </Typography>

          <CommonButton
            onHandleClick={() => {}}
            buttonText="Order now"
            hasButtonIcon={false}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CSTSCARD;

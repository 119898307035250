import { Grid } from "@mui/material";
import { IDocument } from "../../../types/userCourse";

import CertificateCard from "./CertificateCard";

const CertificateList = ({
  displayCertificates,
  activeNumber,
}: {
  displayCertificates: IDocument[];
  activeNumber: "available" | "claimed" | "pending";
}) => {
  return (
    <Grid container spacing={2}>
      {displayCertificates?.map((certificate) => (
        <Grid item key={certificate.course_id}>
          <CertificateCard
            certificate={certificate}
            activeCertificate={activeNumber}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default CertificateList;

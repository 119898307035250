import { Alert, Snackbar } from "@mui/material";

const SiteSnackBar = ({
  key,
  open,
  message,
  handleClose,
  severity,
  autoHideDuration = 6000,
}: {
  key: any;
  open: boolean;
  message: string;
  handleClose: () => void;
  severity: "success" | "error" | "info" | "warning";
  autoHideDuration?: number;
}) => {
  return (
    <Snackbar
      key={key}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SiteSnackBar;

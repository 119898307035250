import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const CommonModal = ({
  open,
  handleClose,
  pdfUrl,
  title,
}: {
  open: boolean;
  handleClose: () => void;
  pdfUrl: string;
  title: string;
}) => {
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiDialog-paper": {
            width: "90%",
            maxWidth: "50rem",
            maxHeight: "40rem",
            height: "auto",
            borderRadius: "1rem",
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <Typography gutterBottom>{title}</Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
            },
            height: "30rem",
          }}
        >
          {/* display certificate pdf in iframe without prompting a download,*/}

          <iframe src={pdfUrl} width="100%" height="500px"></iframe>
        </DialogContent>
        <DialogActions>
          <Button
            LinkComponent={"a"}
            href={pdfUrl}
            download
            variant="contained"
            color="primary"
            sx={{
              textTransform: "capitalize",
              fontSize: "1rem",
              "&:hover": { backgroundColor: "secondary", color: "white" },
            }}
          >
            Download
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default CommonModal;

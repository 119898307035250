import {
  Box,
  Paper,
  Typography,
  Stack,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { ILearningProduct } from "../../../types";
import CommonButton from "../../Common/buttons/CommonButton";
import { useState } from "react";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
const TeamUnlimitedLearning = ({
  bulkCourseProduct,
  teamLSubscriptionProduct,
}: {
  bulkCourseProduct: ILearningProduct | undefined;
  teamLSubscriptionProduct: ILearningProduct | undefined;
}) => {
  //   const [bulkProductQuantity, setBulkProductQuantity] = useState(3);
  const [teamProductQuantity, setTeamProductQuantity] = useState(3);
  const [bulkProductQuantity, setBulkProductQuantity] = useState(3);
  const onChangeQuantity = (e: any) => {
    const newQuantity = +e.target.value;
    if (newQuantity < 3) {
      setTeamProductQuantity(3);
    } else if (newQuantity > 99) {
      setTeamProductQuantity(99);
    } else {
      setTeamProductQuantity(newQuantity);
    }
  };

  const onChangeBulkQuantity = (e: any) => {
    const newQuantity = +e.target.value;
    if (newQuantity < 3) {
      setBulkProductQuantity(3);
    } else if (newQuantity > 99) {
      setBulkProductQuantity(99);
    } else {
      setBulkProductQuantity(newQuantity);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        width: {
          xs: "100%",
          md: "50%",
        },
      }}
    >
      {" "}
      <Paper
        sx={{
          padding: "2.5rem",
          margin: "1rem",
          borderRadius: "1.5rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          backgroundColor: "primary.400",
          color: "white",
          boxShadow: "0px 2px 12px 0px rgba(20, 20, 43, 0.08)",
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "bold", color: "white" }}>
          {teamLSubscriptionProduct?.title}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "white", opacity: "0.7" }}>
          {teamLSubscriptionProduct?.short_description}
        </Typography>

        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Typography variant="h4" sx={{ fontWeight: "bold", color: "white" }}>
            £{teamLSubscriptionProduct?.price}{" "}
            <span
              style={{
                fontSize: "1.125rem",
                fontWeight: "normal",
                color: "white",
                opacity: "0.7",
              }}
            >
              only
            </span>
          </Typography>
          <Box sx={{ display: "flex", gap: "0.3rem", alignItems: "center" }}>
            <IconButton
              onClick={() =>
                teamProductQuantity > 3 &&
                setTeamProductQuantity((prev) => prev - 1)
              }
            >
              <RemoveCircleOutlinedIcon
                sx={{
                  color: "white",
                  backgroundColor: "transparent",
                }}
              />
            </IconButton>
            <OutlinedInput
              value={teamProductQuantity}
              onChange={onChangeQuantity}
              sx={{
                color: "white",
                backgroundColor: "transparent",
                width: "2.5rem",
                height: "2rem",
                textAlign: "center",
                "& input": {
                  padding: "0.5rem",
                  color: "white",
                  textAlign: "center",
                },
                "& fieldset": {
                  borderColor: "white",
                },
              }}
            />
            <IconButton
              onClick={() =>
                teamProductQuantity < 99 &&
                setTeamProductQuantity((prev) => prev + 1)
              }
            >
              <AddCircleOutlinedIcon
                sx={{
                  color: "white",
                  backgroundColor: "transparent",
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Stack direction="row" spacing={2}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "500",
              color: "white",
              opacity: "0.7",
              fontSize: "1.125rem",
            }}
          >
            Normally{" "}
            <span style={{ textDecoration: "line-through" }}>
              £{teamLSubscriptionProduct?.regular_price}
            </span>
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "700",
              color: "white",
              fontSize: "1.125rem",
              bgcolor: "secondary.main",
              borderRadius: "2rem",
              padding: "0.5rem 1rem",
            }}
          >
            Save {teamLSubscriptionProduct?.discount_percent}%
          </Typography>
        </Stack>
        <CommonButton
          buttonText="Get it now"
          hasButtonIcon={true}
          buttonStyles={{ bgcolor: "white", color: "primary.400" }}
          endIconStrokeColor="#3F4D97"
          linkUrl={`/cart/?add-to-cart=${teamLSubscriptionProduct?.id}&quantity=${teamProductQuantity}`}
        />
      </Paper>
      <Paper
        sx={{
          padding: "2.5rem",
          margin: "1rem",
          borderRadius: "1.5rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          boxShadow: "0px 2px 12px 0px rgba(20, 20, 43, 0.08)",
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          {bulkCourseProduct?.title}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "grey.300" }}>
          {bulkCourseProduct?.short_description}
        </Typography>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            £{bulkCourseProduct?.price}{" "}
          </Typography>
          <Box sx={{ display: "flex", gap: "0.3rem", alignItems: "center" }}>
            <IconButton
              onClick={() =>
                bulkProductQuantity > 3 &&
                setBulkProductQuantity((prev) => prev - 1)
              }
            >
              <RemoveCircleOutlinedIcon color="primary" />
            </IconButton>
            <OutlinedInput
              value={bulkProductQuantity}
              onChange={onChangeBulkQuantity}
              sx={{
                color: "white",

                width: "2.5rem",
                height: "2rem",
                textAlign: "center",
                "& input": {
                  padding: "0.5rem",
                  textAlign: "center",
                  color: "black",
                },
              }}
            />
            <IconButton
              onClick={() =>
                bulkProductQuantity < 99 &&
                setBulkProductQuantity((prev) => prev + 1)
              }
            >
              <AddCircleOutlinedIcon color="primary" />
            </IconButton>
          </Box>
        </Box>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "700",
            color: "white",
            fontSize: "1.125rem",
            bgcolor: "secondary.main",
            borderRadius: "2rem",
            padding: "0.5rem 1rem",
            width: "fit-content",
          }}
        >
          Save {bulkCourseProduct?.discount_percent}%
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ fontWeight: "500", color: "grey.300" }}
        >
          Buy any number of courses for you, a friend, family, or team members
        </Typography>
        <CommonButton
          buttonText="Get it now"
          hasButtonIcon={true}
          linkUrl={`/cart/?add-to-cart=${bulkCourseProduct?.id}&quantity=${bulkProductQuantity}`}
        />
      </Paper>
    </Box>
  );
};

export default TeamUnlimitedLearning;

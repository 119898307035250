import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContentTitle from "../../Common/ContentTitle";
import DashboardNumbers from "../../Common/DashboardNumbers";
import { IDocument } from "../../../types/userCourse";
import TranscriptList from "./TranscriptList";
import { useContext, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import AppContext from "../../../contexts/AppContext";
import { getUserCertificates } from "../../../utils/apiCall";

const MyTranscripts = () => {
  const userToken = useContext(AppContext).token;
  const { data: transcriptList, isLoading } = useQuery<IDocument[]>({
    queryKey: ["certificateList"],
    queryFn: () => getUserCertificates(userToken),
    enabled: !!userToken,
  });

  console.log(transcriptList);

  const [activeNumber, setActiveNumber] = useState<
    "available" | "claimed" | "pending"
  >("claimed");

  const displayTranscripts = useMemo(() => {
    let filteredTranscripts: IDocument[] = [];
    const availableTranscripts = transcriptList?.filter((transcript) => {
      return transcript.is_transcript_unlocked && !transcript.transcript_url;
    });
    const claimedTranscript = transcriptList?.filter((transcript) => {
      return transcript.transcript_url;
    });
    const pendingTranscripts = transcriptList?.filter((transcript) => {
      return !transcript.transcript_url && !transcript.is_transcript_unlocked;
    });
    if (activeNumber === "available") {
      filteredTranscripts = availableTranscripts || [];
    } else if (activeNumber === "claimed") {
      filteredTranscripts = claimedTranscript || [];
    } else {
      filteredTranscripts = pendingTranscripts || [];
    }

    return {
      availableTranscripts: availableTranscripts || [],
      claimedTranscript: claimedTranscript || [],
      pendingTranscripts: pendingTranscripts || [],
      filteredTranscripts,
    };
  }, [activeNumber, transcriptList]);

  const handleNumberClick = (
    _event: React.SyntheticEvent,
    newValue: "available" | "claimed" | "pending"
  ) => {
    setActiveNumber(newValue);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      rowGap={"2rem"}
      paddingBottom={"2rem"}
    >
      <ContentTitle title="My Transcripts" />
      <Box
        sx={{
          display: {
            xs: "none",
            md: "flex",
          },
          flexDirection: "column",
        }}
      >
        <DashboardNumbers
          numbers={[
            {
              label: "Available Transcripts",
              count: displayTranscripts.availableTranscripts.length,
              value: "available",
            },
            {
              label: "Claimed Transcripts",
              count: displayTranscripts.claimedTranscript.length,
              value: "claimed",
            },
            {
              label: "Pending Transcripts",
              count: displayTranscripts.pendingTranscripts.length,
              value: "pending",
            },
          ]}
          activeValue={activeNumber}
          handleActive={handleNumberClick}
        />

        <TranscriptList
          displayTranscripts={displayTranscripts.filteredTranscripts}
          activeNumber={activeNumber}
        />
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              sx={{
                fontSize: "1.25rem",
                fontWeight: "bold",
              }}
            >{`Available Transcripts (${displayTranscripts.availableTranscripts.length})`}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TranscriptList
              displayTranscripts={displayTranscripts.availableTranscripts}
              activeNumber={activeNumber}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              sx={{
                fontSize: "1.25rem",
                fontWeight: "bold",
              }}
            >
              {" "}
              {`Claimed Transcripts (${displayTranscripts.claimedTranscript.length})`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TranscriptList
              displayTranscripts={displayTranscripts.claimedTranscript}
              activeNumber={activeNumber}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default MyTranscripts;

import { Box, Typography } from "@mui/material";
import CommonButton from "../../Common/buttons/CommonButton";

const CartNavigation = ({
  currencySymbol,
  total,
}: {
  currencySymbol: string;
  total: number;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        px: "1rem",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6">Total</Typography>
        <Typography variant="h6">
          {currencySymbol}
          {total}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: "1rem",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <CommonButton
          buttonText="View Cart"
          linkUrl="/cart"
          buttonStyles={{
            backgroundColor: "secondary.main",
            color: "grey.50",
          }}
        />
        <CommonButton buttonText="Proceed to Checkout" linkUrl="/checkout" />
      </Box>
    </Box>
  );
};

export default CartNavigation;

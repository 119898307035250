import { useQuery } from "@tanstack/react-query";
import { getBundleCategoryCourses } from "../../../utils/apiCall";
import { IRecommendedCourses } from "../../../types/recommendedCourses";
import { Box, Grid } from "@mui/material";
import SingleBundle from "./SingleBundle";
import CommonAccordion from "../../Common/CommonAccordion";

const BundleCourses = () => {
  const { data: bundleCourses } = useQuery<IRecommendedCourses[]>({
    queryKey: ["bundleCourses"],
    queryFn: () => getBundleCategoryCourses(),
  });

  return (
    <Box display={"flex"} gap={"2rem"} flexDirection={"column"} my={"3rem"}>
      <Grid container spacing={"2rem"}>
        {bundleCourses?.map((bundleCourse) => {
          return (
            <Grid item xs={12} lg={6} key={bundleCourse.ID}>
              <SingleBundle singleBundle={bundleCourse} />
            </Grid>
          );
        })}
      </Grid>
      <CommonAccordion />
    </Box>
  );
};

export default BundleCourses;

import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, Badge, Box, Button, Divider, Menu } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import { styled, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { useQuery } from "@tanstack/react-query";
import logo from "../../assets/images/dashboard-white-logo.svg";
import { ColorModeContext } from "../../App";
import { getUserInfo, logOut } from "../../utils/apiCall";
import { useContext, useState } from "react";
import { IUserInfo } from "../../types";
import HomeIcon from "../Common/icons/HomeIcon";
import CartIcon from "../Common/icons/CartIcon";
const drawerWidth = 280;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface HeaderProps {
  open: boolean;
  handleDrawerOpen: () => void;
  mobileSidebarOpen: boolean;
  onHandleMobileSidebarOpen: () => void;
  onHandleCartOpen: () => void;
  cartItemsNumber: number;
}

const Header: React.FC<HeaderProps> = ({
  open,
  handleDrawerOpen,
  onHandleMobileSidebarOpen,
  onHandleCartOpen,
  cartItemsNumber,
}) => {
  const { data: userInformation } = useQuery<IUserInfo>({
    queryKey: ["userInformation"],
    queryFn: getUserInfo,
  });

  const [profileMenuAnchorEl, setProfileMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const onHandleLogout = async () => {
    try {
      await logOut();
      // reload the page
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  const renderMobileMenu = (
    <Box
      sx={{
        display: { xs: "flex", md: "none" },
        justifyContent: "space-between",
        mx: "1rem",
      }}
    >
      <IconButton
        onClick={onHandleMobileSidebarOpen}
        sx={{
          display: { xs: "flex", md: "none" },
          color: theme.palette.text.primary,
        }}
      >
        <MenuIcon />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "8.5rem",
        }}
      >
        <img
          style={{
            width: "100%",
            objectFit: "contain",
          }}
          src={logo}
          alt="logo"
        />
      </Box>
      <IconButton
        size="large"
        aria-label="show more"
        aria-haspopup="true"
        onClick={() => {}}
        color="inherit"
      >
        <Avatar
          alt={userInformation?.displayname}
          src={userInformation?.avatar}
        />
      </IconButton>
    </Box>
  );
  return (
    <>
      <AppBar
        position="fixed"
        open={open}
        sx={{
          bgcolor: theme.palette.background.paper,
          boxShadow: {
            xs: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
            md: "none",
          },
          py: {
            xs: 0,
            md: 2,
          },
        }}
      >
        <Toolbar
          sx={{
            display: { xs: "none", md: "flex" },
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              color: theme.palette.text.primary,
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              width: "100%",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Button
                sx={{
                  bgcolor: "#F6F6FA",
                  borderRadius: "2rem",
                  padding: "1rem 1.5rem",
                  color: "primary.main",
                  textTransform: "capitalize",
                  fontWeight: 600,
                  fontSize: "1.125rem",
                  mr: 1,
                }}
                LinkComponent={"a"}
                href="/"
                target="_blank"
                startIcon={<HomeIcon />}
              >
                Go to website
              </Button>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  bgcolor: "#EAECEE",
                  height: "2.5rem",
                  width: "2px",
                  margin: "auto",
                }}
              />
              <IconButton
                sx={{
                  color: theme.palette.text.primary,
                  ml: 1,
                }}
                onClick={colorMode.toggleColorMode}
              >
                {theme.palette.mode === "dark" ? (
                  <Brightness7Icon color="secondary" />
                ) : (
                  <Brightness4Icon />
                )}
              </IconButton>

              <Badge
                badgeContent={cartItemsNumber}
                color="secondary"
                sx={{
                  cursor: "pointer",
                  "& path":
                    theme.palette.mode === "dark" ? { stroke: "#fff" } : {},
                }}
                onClick={onHandleCartOpen}
              >
                <CartIcon />
              </Badge>
              <Box sx={{ flexGrow: 0 }}>
                <IconButton
                  onClick={() => {
                    setProfileMenuAnchorEl(
                      profileMenuAnchorEl
                        ? null
                        : (document.querySelector(
                            "#menu-appbar"
                          ) as HTMLElement)
                    );
                  }}
                  sx={{ p: 0 }}
                >
                  <Avatar
                    alt={userInformation?.displayname}
                    src={userInformation?.avatar}
                  />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={profileMenuAnchorEl}
                  open={Boolean(profileMenuAnchorEl)}
                  onClose={() => setProfileMenuAnchorEl(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{ mt: "35px" }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Button
                      sx={{
                        color: theme.palette.text.primary,
                        fontSize: "1rem",
                        textTransform: "capitalize",
                        fontWeight: 600,
                      }}
                      href="/"
                    >
                      Profile
                    </Button>
                    <Button
                      sx={{
                        color: theme.palette.text.primary,
                        fontSize: "1rem",
                        textTransform: "capitalize",
                        fontWeight: 600,
                      }}
                      onClick={onHandleLogout}
                    >
                      Logout
                    </Button>
                  </Box>
                </Menu>
              </Box>
            </Box>
          </Box>
        </Toolbar>

        {renderMobileMenu}
      </AppBar>
    </>
  );
};

export default Header;

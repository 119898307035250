/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { AppActionType } from "../types";
import { getUserSubscriptionDetails } from "../utils/apiCall";
import AppContext from "./AppContext";

const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const initialState = {
    token: "",
    subscription_info: null,
    loading: false,
  };
  const rootReducer = (state: typeof initialState, action: any) => {
    switch (action.type) {
      case AppActionType.SET_TOKEN:
        return { ...state, token: action.payload };
      case AppActionType.SET_SUBSCRIPTION_INFO:
        return { ...state, subscription_info: action.payload };
      case AppActionType.SET_LOADING:
        return { ...state, loading: action.payload };
      default:
        return state;
    }
  };

  const [rootState, dispatch] = useReducer(rootReducer, initialState);

  const fetchUserSubscriptionInfo = useCallback(async () => {
    try {
      dispatch({ type: AppActionType.SET_LOADING, payload: true });
      const response = await getUserSubscriptionDetails(rootState.token);
      console.log(response);
      dispatch({
        type: AppActionType.SET_SUBSCRIPTION_INFO,
        payload: response,
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: AppActionType.SET_LOADING, payload: false });
    }
  }, [rootState.token]);
  const pluginData = (window as any).pluginData;

  useMemo(() => {
    dispatch({ type: AppActionType.SET_TOKEN, payload: pluginData.token });
  }, [pluginData.token]);
  useEffect(() => {
    fetchUserSubscriptionInfo();
  }, [fetchUserSubscriptionInfo]);

  return (
    <AppContext.Provider value={rootState}>{children}</AppContext.Provider>
  );
};

export default AppProvider;
